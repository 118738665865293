<template>
<div>
  <b-container fluid>
    <b-row class="py-4">
      <b-col>
        <h1 style="font-size: 24px; font-weight: bold;">Tabular lineage</h1>
        <p>Select one element in each category to see lineage.</p>
      </b-col>
    </b-row>
    <b-row class="bg-white shadow py-4">
      <b-col>
        <div>
          <b-form-group label="Frameworks" v-slot="{ ariaDescribedby }">
            <b-form-radio-group
              v-model="framework"
              :options="frameworkOptions"
              :aria-describedby="ariaDescribedby"
              name="radios-frameworks"
              stacked
            ></b-form-radio-group>
          </b-form-group>
        </div>
      </b-col>
      <b-col v-if="pillars.length > 0">
        <div>
          <b-form-group label="Pillars" v-slot="{ ariaDescribedby }">
            <b-form-radio-group
              v-model="pillar"
              :options="pillars"
              :aria-describedby="ariaDescribedby"
              name="radios-pillars"
              stacked
            ></b-form-radio-group>
          </b-form-group>
        </div>
      </b-col>
      <b-col v-if="categories.length > 0">
        <div>
          <b-form-group label="Categories" v-slot="{ ariaDescribedby }">
            <b-form-radio-group
              v-model="category"
              :options="categories"
              :aria-describedby="ariaDescribedby"
              name="radios-categories"
              stacked
            ></b-form-radio-group>
          </b-form-group>
        </div>
      </b-col>
      <b-col v-if="metricsX.length > 0">
        <div>
          <b-form-group label="Metrics" v-slot="{ ariaDescribedby }">
            <b-form-radio-group
              v-model="metric"
              :options="metricsX"
              :aria-describedby="ariaDescribedby"
              name="radios-metrics"
              stacked
            ></b-form-radio-group>
          </b-form-group>
        </div>
      </b-col>
      <b-col v-if="dataelementsX.length > 0">
        <div>
          <b-form-group label="Data elements" v-slot="{ ariaDescribedby }">
            <b-form-radio-group
              v-model="dataelement"
              :options="dataelementsX"
              :aria-describedby="ariaDescribedby"
              name="radios-dataelements"
              stacked
            ></b-form-radio-group>
          </b-form-group>
        </div>
      </b-col>
      <b-col v-if="metrics2.length > 0">
        <div>
          <b-form-group label="Related metrics" v-slot="{ ariaDescribedby }">
            <b-form-radio-group
              v-model="metric2"
              :options="metrics2"
              :aria-describedby="ariaDescribedby"
              name="radios-metrics2"
              stacked
            ></b-form-radio-group>
          </b-form-group>
        </div>
      </b-col>
      <b-col v-if="frameworks2.length > 0">
        <div>
          <b-form-group label="Related frameworks" v-slot="{ ariaDescribedby }">
            <b-form-radio-group
              v-model="framework2"
              :options="frameworks2"
              :aria-describedby="ariaDescribedby"
              name="radios-frameworks2"
              stacked
            ></b-form-radio-group>
          </b-form-group>
        </div>
      </b-col>
    </b-row>
  </b-container>
</div>
</template>

<script>
import _ from 'lodash'

export default {
  name: 'Lineage',
  components: {
  },
  computed: {
    dataelements: function () {
      return this.$store.state.dataelements
    },
    frameworks: function () {
      return this.$store.state.reportingframeworks
    },
    frameworkOptions: function () {
      return this.frameworks.map(framework => {
        return {
          value: framework,
          text: framework.name
        }
      })
    },
    metrics: function () {
      return this.$store.state.metrics
    },
    metricsFramework: function () {
      if (this.framework === null) {
        return []
      }
      return this.metrics.filter(metric => metric.reportingframeworks.some(row => row.id === this.framework.id))
    },
    metricsPillar: function () {
      if (this.pillar === null) {
        return []
      }
      return this.metricsFramework.filter(metric => metric.taxonomy.some(taxonomy => taxonomy.parents.some(parent => parent.id === this.pillar.id)))
    },
    metricsTaxonomy: function () {
      if (this.category === null) {
        return []
      }
      const m = this.metricsPillar.filter(metric => metric.taxonomy.some(taxonomy => taxonomy.id === this.category.id))
      const result = _.orderBy(_.uniqBy(m, 'id'), ['name'], ['asc'])
      return result
    },
    pillars: function () {
      if (this.framework === null) {
        return []
      }
      let pillars = []
      this.metricsFramework.forEach(metric => {
        metric.taxonomy.forEach(taxonomy => {
          taxonomy.parents.forEach(parent => {
            pillars.push(parent)
          })
        })
      })
      // make pilar unique and sort by name
      pillars = _.orderBy(_.uniqBy(pillars, 'id'), ['name'], ['asc'])
      const results = pillars.map(pillar => {
        return {
          value: pillar,
          text: pillar.name
        }
      })
      return results
    },
    categories: function () {
      if (this.pillar === null) {
        return []
      }
      let taxonomy = []
      this.metricsPillar.forEach(metric => {
        metric.taxonomy.forEach(taxonomyRow => {
          taxonomy.push(taxonomyRow)
        })
      })
      taxonomy = _.orderBy(_.uniqBy(taxonomy, 'id'), ['name'], ['asc'])
      const results = taxonomy.map(taxonomy => {
        return {
          value: taxonomy,
          text: taxonomy.name
        }
      })
      return results
    },
    metricsX: function () {
      if (this.category === null) {
        return []
      }
      const results = this.metricsTaxonomy.map(metric => {
        return {
          value: metric,
          text: metric.name
        }
      })
      return results
    },
    dataelementsX: function () {
      if (this.metric === null) {
        return []
      }
      const metric = this.metricsTaxonomy.find(row => row.id === this.metric.id)
      const dataelements = _.orderBy(_.uniqBy(metric.dataelements, 'id'), ['name'], ['asc'])
      let results = dataelements.map(dataelement => {
        return {
          text: _.truncate(dataelement.name, { length: 70 }),
          value: dataelement
        }
      })
      return results
    },
    metrics2: function () {
      if (this.dataelement === null) {
        return []
      }
      let dataelements = this.dataelements.find(row => row.id === this.dataelement.id)
      dataelements = _.orderBy(_.uniqBy(dataelements.metrics, 'id'), ['name'], ['asc'])
      let results = dataelements.map(metric => {
        return {
          text: _.truncate(metric.name, { length: 70 }),
          value: metric
        }
      })
      return results
    },
    frameworks2: function () {
      if (this.metric2 === null) {
        return []
      }
      const metric = this.metrics.find(row => row.id === this.metric2.id)
      const frameworks = _.orderBy(_.uniqBy(metric.reportingframeworks, 'id'), ['name'], ['asc'])
      let results = frameworks.map(framework => {
        return {
          text: framework.name,
          value: framework
        }
      })
      return results
    }
  },
  data () {
    return {
      category: null,
      dataelement: null,
      framework: null,
      framework2: null,
      metric: null,
      metric2: null,
      pillar: null
    }
  },
  created: function () {
    document.title = "ESG Metrics"
  },
  methods: {
    clearCategory: function () {
      this.category = null
      this.clearMetric()
    },
    clearDataelement: function () {
      this.dataelement = null
      this.clearMetric2()
    },
    clearFramework2: function () {
      this.framework2 = null
    },
    clearPillar: function () {
      this.pillar = null
      this.clearCategory()
    },
    clearMetric: function () {
      this.metric = null
      this.clearDataelement()
    },
    clearMetric2: function () {
      this.metric2 = null
      this.clearFramework2()
    }
  },
  watch: {
    framework: 'clearPillar',
    category: 'clearMetric',
    dataelement: 'clearMetric2',
    metric: 'clearDataelement',
    metric2: 'clearFramework2',
    pillar: 'clearCategory'
  }
}
</script>

<style>
</style>
